export const BANNER = {
  CONNECTING: "banner_state_CONNECTING",
  STARTING: "banner_state_STARTING",
  SEARCHING: "banner_state_SEARCHING",
  SCANNING: "banner_state_SCANNING",
  SUCCESS: "banner_state_SUCCESS",
  INCOMPLETE: "banner_state_INCOMPLETE",
  DISCONNECTED: "banner_state_DISCONNECTED",
  WEBRTC_FAILED: "banner_state_WEBRTC_FAILED",
  CANCELLED: "banner_state_CANCELLED",
  INVALID: "banner_state_INVALID",
};
