import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { RTCContext, VitalsStateContext, MediaContext } from "../../App";
import theme from "../../utils/theme";
import { BANNER } from "../../consts/bannerTexts";
import { VitalsState } from "../../types/vitalsState";
import { useTranslation } from "react-i18next";

export const Banner: React.FC<any> = () => {
  const { clientHeight, isLandscape } = useContext(MediaContext)!;
  const { dataChannel } = useContext(RTCContext)!;
  const {
    vitalsState: { state, stateColor, isCancelled },
    dispatchVitalsState,
  } = useContext(VitalsStateContext)!;

  const variableColor = useMemo(
    () => (stateColor === theme.colors.red.main ? "#fff" : "#000"),
    [stateColor]
  );

  const onCancelScan = () => {
    dataChannel?.send(
      JSON.stringify({
        type: "command",
        message: "stop",
      })
    );
    dispatchVitalsState({ type: "CANCEL_SCAN" });
  };
  const {t} = useTranslation();

  return (
    <HStack
      justify="center"
      align="center"
      w="100%"
      h={isLandscape ? `${0.14 * clientHeight}px` : "auto"}
      p={isLandscape ? "0 10.3%" : { base: "7.5vw 13vw", md: "5.34vw" }}
      bg={stateColor}
      width="100%"
      fontSize={
        isLandscape
          ? {
              base: "16px",
              lg: "28px",
              xl: "36px",
            }
          : {
              base: "20px",
              md: "36px",
              lg: "44px",
            }
      }
      position="relative"
    >
      <Text mr={isLandscape ? "0" : "2.54vw"} color={variableColor}>
        <FaRegCheckCircle />
      </Text>
      <Text textAlign="center" lineHeight="1.25" color={variableColor}>
        {!isCancelled ? t(BANNER[state]) : t(BANNER.CANCELLED)}
      </Text>
      {dataChannel &&
        (state === VitalsState.SEARCHING || state === VitalsState.SCANNING) &&
        !isCancelled && (
          <Button
            onClick={onCancelScan}
            position="absolute"
            bottom={
              isLandscape
                ? {
                    base: `${0.014 * clientHeight}px`,
                    lg: `${0.02 * clientHeight}px`,
                  }
                : "2.4vw"
            }
            left="50%"
            transform="translate(-50%, 25%)"
            color={variableColor}
            fontWeight="500"
            size={
              isLandscape
                ? { base: "xxxs", lg: "xxs", xl: "xs" }
                : { base: "xxs", md: "xs", lg: "sm" }
            }
            leftIcon={<IoCloseSharp color={variableColor} fontSize="14px" />}
            colorScheme="initial"
            bgColor="transparent"
            border="1px solid"
            borderColor={variableColor}
          >
            <Box>{t('cancel_scan')}</Box>
          </Button>
        )}
    </HStack>
  );
};
