import { HStack, Image, Stack } from "@chakra-ui/react";
import { useContext } from "react";
import { MediaContext } from "../../App";
import draganfly from "../../images/draganfly_logo.png";
import vi from "../../images/vitalIntelligence_logo.png";
import FooterInstruction from "./FooterInstruction";

export const Footer = () => {
  const { isLandscape } = useContext(MediaContext)!;

  return (
    <Stack
      width="100%"
      bg="#fff"
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align="center"
      spacing={{ base: "4vw", md: 6 }}
      p={{ base: "0 2.9vw", md: "1.17vw 2.9vw" }}
      mt={{ base: "10vw", md: "3vw" }}
    >
      <HStack justify={{ base: "space-around", md: "normal" }}>
        <Image
          src={draganfly}
          w={isLandscape ? "auto" : { base: "45%", md: "19.6vw" }}
          maxW="200px"
          maxH="50px"
        />
        <Image
          src={vi}
          w={isLandscape ? "auto" : { base: "45%", md: "18.8vw" }}
          maxW="200px"
          maxH="50px"
        />
      </HStack>
      <FooterInstruction />
    </Stack>
  );
};
