import { ISvg } from "../types/image";

export const LungsOutlined: React.FC<ISvg> = ({
  w = "30",
  h = "31",
  stroke = "currentColor",
}) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 30 31"
    fill="none"
    stroke={stroke}
    strokeWidth={0}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M30.0001 16.5927C30.0001 12.0444 26.3227 8.36694 21.7743 8.36694H20.3227V11.2702H21.7743C24.7259 11.2702 27.0969 13.6411 27.0969 16.5927V24.3831C27.0969 26.2218 25.5969 27.7218 23.7581 27.7218H23.6614C21.8227 27.7218 20.3227 26.2218 20.3227 24.3831V20.9476H17.4194V24.3831C17.4194 27.8186 20.2259 30.625 23.6614 30.625H23.7581C27.1936 30.625 30.0001 27.8186 30.0001 24.3831V16.5927Z"
        fill={stroke}
      />
      <path
        d="M0 16.5927V24.3831C0 27.8186 2.80645 30.625 6.24194 30.625H6.33871C9.77419 30.625 12.5806 27.8186 12.5806 24.3831V20.9476H9.67742V24.3831C9.67742 26.2218 8.17742 27.7218 6.33871 27.7218H6.24194C4.40323 27.7218 2.90323 26.2218 2.90323 24.3831V16.5927C2.90323 13.6411 5.27419 11.2702 8.22581 11.2702H9.67742V8.36694H8.22581C3.67742 8.36694 0 12.0444 0 16.5927Z"
        fill={stroke}
      />
      <path
        d="M16.4513 0.625H13.5481V10.7379C13.5481 12.5282 12.4836 14.125 10.7901 14.8024L8.27393 15.8185L9.33844 18.5282L11.8546 17.5121C13.1126 17.0282 14.1772 16.2056 14.9513 15.1411C15.7739 16.2056 16.8384 17.0282 18.0481 17.5121L20.5642 18.5282L21.6288 15.8185L19.1126 14.8024C17.4675 14.125 16.3546 12.5282 16.3546 10.7379V0.625H16.4513Z"
        fill={stroke}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(0 0.625)"
        />
      </clipPath>
    </defs>
  </svg>
);
