import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    red: {
      main: "#EB5757",
    },
    orange: {
      main: "#F2994A",
    },
    yellow: {
      main: "#F3D35E",
    },
    green: {
      main: "#04E77A",
    },
  },
  breakpoints: {
    sm: "320px", // Phones
    md: "768px", // Tablets
    lg: "960px", // iPad Pro 12.9", small PC screen
    xl: "1200px", // PC
    xxl: "1536px",
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "bold", // Normally, it is "semibold"
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xxxs: {
          fontSize: "8px",
          h: "14.5px",
          px: "9px",
        },
        xxs: {
          fontSize: "10px",
          h: "20px",
          px: "10px",
        },
        xs: {
          fontSize: "10px",
          h: "28px",
          px: "10px",
        },
        sm: {
          fontSize: "14px",
          h: "32px",
          px: "12px",
        },
        md: {
          fontSize: "18px",
          h: "48px",
          px: "24px",
        },
        lg: {
          fontSize: "22px",
          h: "64px",
          px: "28px",
        },
        xl: {
          fontSize: "26px",
          h: "80px",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
        },
        // 4. We can override existing variants
        // solid: (props: StyleFunctionProps) => ({
        //   bg: props.colorMode === "dark" ? "red.300" : "red.500",
        // }),
        // 5. We can add responsive variants
        // sm: {
        //   bg: "teal.500",
        //   fontSize: "md",
        // },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: "lg", // default is md
        variant: "solid", // default is solid
      },
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: "white",
        color: "white",

        // prohibit the scroll
        overflow: "hidden",
      },
      html: {
        // prohibit the scroll
        overflow: "hidden",
      },
      // styles for the `a`
      a: {
        color: "teal.500",
        _hover: {
          textDecoration: "underline",
        },
      },
      p: {
        color: "#0A2B37",
        font: "Lausanne Sandland",
      },
    },
  },
});

export default theme;
