import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CAMERA_QUERY, TOKEN } from "../consts/rooting";

// Hook to get a query called "rear_camera"
export const useURLQuery = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cameraQuery = queryParams.get(CAMERA_QUERY);
  const [isRearCamera, setIsRearCamera] = useState<boolean | null>(null);
  const token = queryParams.get(TOKEN)

  useEffect(() => {
    if (cameraQuery === "true") setIsRearCamera(true);
    else setIsRearCamera(false);
  }, [cameraQuery]);

  return { isRearCamera, setIsRearCamera, queryParams, cameraQuery, token };
};
