import { Box, Button, VStack } from "@chakra-ui/react";
import { useContext } from "react";
import { MediaContext } from "../../App";
import theme from "../../utils/theme";
import { useTranslation } from "react-i18next";

type ResultProps = {
  rescan: () => void;
  colour: string;
  Icon: any;
  text: string;
};

const Result = ({ rescan, colour, Icon, text }: ResultProps) => {
  const { isLandscape } = useContext(MediaContext)!;
  const {t} = useTranslation();

  return (
    <VStack
      align="center"
      spacing={
        isLandscape
          ? { base: "1.4vw", lg: "2.06vw" }
          : { base: "2.2vw", md: "3.125vw" }
      }
      w="100%"
    >
      <Box>{Icon}</Box>
      <Box
        w={isLandscape ? "38.68vw" : "70vw"}
        fontSize={
          isLandscape
            ? {
                base: "28px",
                md: "32px",
                lg: "48px",
              }
            : {
                base: "26px",
                md: "48px",
                lg: "64px",
              }
        }
        fontWeight="bold"
        lineHeight="1.4"
        color={colour}
        textAlign="center"
      >
        {text}
      </Box>
      <Button
        size={
          isLandscape
            ? {
                base: "xs",
                md: "sm",
                lg: "md",
                xxl: "lg",
              }
            : {
                base: "xs",
                md: "md",
                lg: "lg",
                xxl: "xl",
              }
        }
        onClick={rescan}
        bgColor={colour}
        color={colour === theme.colors.red.main ? "#fff" : "#000"}
      >
        {t('video_scan_again')}
      </Button>
    </VStack>
  );
};

export default Result;
