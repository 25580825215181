export const TEXT = {
  WELCOME: "WELCOME",
  SEARCHING: "SEARCHING",
  SCANNING: "SCANNING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED.",
  INVALID: "INVALID",
};

export const INSTRUCTIONS = {
  CONNECTING: TEXT.WELCOME,
  STARTING: TEXT.WELCOME,
  SEARCHING: TEXT.SEARCHING,
  SCANNING: TEXT.SCANNING,
  SUCCESS: TEXT.SUCCESS,
  INCOMPLETE: TEXT.FAILED,
  DISCONNECTED: TEXT.FAILED,
  WEBRTC_FAILED: TEXT.FAILED,
  INVALID: TEXT.INVALID,
};
