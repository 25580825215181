import { Box, Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import default_logo from "../../images/Vital-Intelligence-sticker.png";
import default_rules from "../../images/default_rules.png";
import { useTranslation } from "react-i18next";

const DEFAULT_ORG_SETTINGS = {
  logoUri: default_logo,
  rulesUri: default_rules,
};

export const Details = () => {
  const {t} = useTranslation();

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      justify="space-between"
      align="center"
      p={{ base: "7vw 2.9vw 0", md: "4.88vw 2.9vw 0" }}
    >
      <HStack
        spacing={{ base: "0", md: "3.125vw" }}
        mb={{ base: "4.8vw", md: 0 }}
        justify={{ base: "space-around", md: "normal" }}
      >
        <Box
          w={{ base: "15vw", md: "12.89vw" }}
          h={{ base: "15vw", md: "12.89vw" }}
          maxW="96px"
          maxH="96px"
        >
          <Image
            src={DEFAULT_ORG_SETTINGS.logoUri}
            borderRadius={100}
            w="100%"
            h="100%"
          />
        </Box>
        <Box w={{ base: "70%", md: "36vw" }}>
          <Text
            fontSize={{ base: "18px", md: "24px", lg: "32px" }}
            fontWeight="550"
          >
            {t('portrait_welcome_message')}
          </Text>
        </Box>
      </HStack>
      <Flex justify="center" align="center">
        <Image
          src={DEFAULT_ORG_SETTINGS.rulesUri}
          w={{ base: "85%", md: "34.1vw" }}
          h={{ base: "auto", md: "11.72vw" }}
          maxW="349px"
          maxH="120px"
        />
      </Flex>
    </Stack>
  );
};
