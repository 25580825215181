import {
  Image,
  Box,
  ChakraProvider,
  Flex,
  useMediaQuery,
  VStack,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { createContext, useEffect, useReducer, useState} from "react";
import { Banner } from "./components/banner";
import { Details } from "./components/details";
import { Footer } from "./components/footer";
import { Video } from "./components/video";
import { QUERY_LANDSCAPE } from "./consts/chakraUI";
import theme from "./utils/theme";
import draganfly from "./images/draganfly_logo.png";
import vi from "./images/vitalIntelligence_logo.png";
import Instruction from "./components/details/Instruction";
import default_rules from "./images/default_rules.png";
import FooterInstruction from "./components/footer/FooterInstruction";
import { IVitalsStateContext } from "./types/vitalsState";
import { initialVitalsState, vitalsStateReducer } from "./reducers/vitalsState";
import { IRTCContext } from "./types/RTC";
import { IMediaContext } from "./types/media";
import { BrowserRouter } from "react-router-dom";
import './i18n'

export const RTCContext = createContext<IRTCContext | null>(null);
export const VitalsStateContext = createContext<IVitalsStateContext | null>(
  null
);
export const MediaContext = createContext<IMediaContext | null>(null);

if (process.env.REACT_APP_TARGET !== "development")
    console.log = () => {};

export const App = () => {
  const [vitalsState, dispatchVitalsState] = useReducer(
    vitalsStateReducer,
    initialVitalsState
  );
  const [isLandscape] = useMediaQuery(QUERY_LANDSCAPE); // When this flag is true, the browser window is horizontal.
  const clientHeight = document.documentElement.clientHeight;
  const clientHeightStr = `${String(clientHeight)}px`;
  const [dataChannel, setDataChannel] = useState<RTCDataChannel | null>(null);
  const [videoInputDevices, setVideoInputDevices] = useState([]);

  useEffect(() => {
    // prohibit the scroll on iOS devices
    document.addEventListener(
      "touchmove",
      (event) => {
        event.preventDefault();
      },
      { passive: false }
    );
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <MediaContext.Provider
        value={{
          isLandscape,
          clientHeight,
          clientHeightStr,
          videoInputDevices,
          setVideoInputDevices,
        }}
      >
        <RTCContext.Provider value={{ dataChannel, setDataChannel }}>
          <VitalsStateContext.Provider
            value={{ vitalsState, dispatchVitalsState }}
          >
            <BrowserRouter>
              <Flex
                w="100vw"
                h={clientHeightStr}
                direction={isLandscape ? "row" : "column"}
              >
                <Video />
                {isLandscape ? (
                  <Box w="34vw" h={clientHeightStr}>
                    <VStack
                      h={`${0.7925 * clientHeight}px`}
                      justify="space-between"
                      p={`${0.046875 * clientHeight}px 5.16%`}
                    >
                      <HStack
                        justify="space-around"
                        align="center"
                        h={{
                          base: `${0.08 * clientHeight}px`,
                          lg: `${0.0547 * clientHeight}px`,
                        }}
                        w="100%"
                      >
                        <Image
                          src={draganfly}
                          h="100%"
                          objectFit="cover"
                          maxW="201px"
                          maxH="57px"
                        />
                        <Image
                          src={vi}
                          h="100%"
                          objectFit="cover"
                          maxW="193.5px"
                          maxH="47px"
                        />
                      </HStack>
                      <Spacer />
                      <Instruction />
                      <Spacer />
                      <Box>
                        <Image
                          src={default_rules}
                          h={{
                            base: `${0.14 * clientHeight}px`,
                            lg: `${0.1172 * clientHeight}px`,
                          }}
                          maxW="349px"
                          maxH="120px"
                        />
                      </Box>
                    </VStack>
                    <Box h={`${0.2075 * clientHeight}px`}>
                      <Banner />
                      <FooterInstruction />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Banner />
                    <Details />
                    <Footer />
                  </>
                )}
              </Flex>
            </BrowserRouter>
          </VitalsStateContext.Provider>
        </RTCContext.Provider>
      </MediaContext.Provider>
    </ChakraProvider>
  );
};
