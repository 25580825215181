import { Flex, Icon, Link } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { MediaContext } from "../../App";
import { CAMERA_QUERY } from "../../consts/rooting";
import { useURLQuery } from "../../hooks/useURLQuery";
import { MdCameraswitch } from "react-icons/md";
import { isMobile } from "react-device-detect";

type ResponsiveStyles = {
  [key: string]: any;
};

type FlipCameraButtonProps = {
  top?: string | ResponsiveStyles;
  left?: string | ResponsiveStyles;
  right?: string | ResponsiveStyles;
  bottom?: string | ResponsiveStyles;
};

const FlipCameraButton = ({
  top = "initial",
  left = "initial",
  right = "initial",
  bottom = "initial",
}: FlipCameraButtonProps) => {
  const { isLandscape, clientHeight, videoInputDevices } =
    useContext(MediaContext)!;
  const { isRearCamera } = useURLQuery();
  const [iconSize, setIconSize] = useState({});
  const [wrapperSize, setWrapperSize] = useState({});

  useEffect(() => {
    if (isLandscape !== null) {
      setIconSize(
        isLandscape
          ? {
              base: `${clientHeight * 0.075}px`,
              lg: `${clientHeight * 0.04}px`,
            }
          : "5.08vw"
      );
      setWrapperSize(
        isLandscape
          ? { base: `${clientHeight * 0.1}px`, lg: `${clientHeight * 0.06}px` }
          : "7.8vw"
      );
    }
  }, [isLandscape, clientHeight]);

  return (
    <>
      {isMobile && videoInputDevices.length >= 2 && (
        <Flex
          justify="center"
          align="center"
          position="absolute"
          top={top}
          left={left}
          right={right}
          bottom={bottom}
          w={wrapperSize}
          h={wrapperSize}
          zIndex="20"
          borderRadius="999px"
          bgColor="rgba(0,0,0,0.5)"
        >
          <Link
            w={iconSize}
            h={iconSize}
            href={`/?${CAMERA_QUERY}=${(!isRearCamera).toString()}`}
          >
            <Icon fontSize={iconSize} color="#fff" as={MdCameraswitch} />
          </Link>
        </Flex>
      )}
    </>
  );
};

export default FlipCameraButton;
