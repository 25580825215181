import { HStack } from "@chakra-ui/react";
import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { MediaContext } from "../../App";
import { Drop2Outlined } from "../../images/Drop2Outlined";
import { HeartRateOutlined } from "../../images/HeartRateOutlined";
import { LungsOutlined } from "../../images/LungsOutlined";
import VitalsItem from "./VitalsItem";

type VitalsItemsProps = {
  vitals: any;
};

const VitalsItems = ({ vitals }: VitalsItemsProps) => {
  const { isLandscape } = useContext(MediaContext)!;

  return (
    <HStack spacing="1.17%">
      <VitalsItem
        colour="#F6CCAD"
        value={vitals?.hr}
        name={`Heart\nRate`}
        Icon={
          <HeartRateOutlined
            w={isLandscape ? "1.93vw" : isMobile ? "4.75vw" : "2.93vw"}
            h={isLandscape ? "1.94vw" : isMobile ? "4.76vw" : "2.94vw"}
          />
        }
      />
      <VitalsItem
        colour="#D3E7EB"
        value={vitals?.br}
        Icon={
          <LungsOutlined
            w={isLandscape ? "1.93vw" : isMobile ? "4.75vw" : "2.93vw"}
            h={isLandscape ? "1.94vw" : isMobile ? "4.76vw" : "2.94vw"}
          />
        }
        name={`Breathing\nRate`}
      />
      <VitalsItem
        colour="#E2D5EC"
        value={vitals?.spo2}
        Icon={
          <Drop2Outlined
            w={isLandscape ? "1.93vw" : isMobile ? "4.75vw" : "2.93vw"}
            h={isLandscape ? "1.94vw" : isMobile ? "4.76vw" : "2.94vw"}
          />
        }
        name="SpO2"
      />
    </HStack>
  );
};

export default VitalsItems;
