import { Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import appConfig from "../../../package.json";
import { useFitText } from "../../hooks/useFitText";
import { MediaContext } from "../../App";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";

const fontSizeConfig = {
  MIN_FONT_SIZE: 5,
  MAX_FONT_SIZE: 100,
  RESOLUTION: 5,
};

const FooterInstruction = () => {
  const { clientHeight, isLandscape } = useContext(MediaContext)!;
  const { fontSize, ref } = useFitText(fontSizeConfig);

  const { t } = useTranslation();

  return (
    <Flex
      w={isLandscape ? "100%" : { base: "100%", md: "47.07vw" }}
      h={
        isLandscape
          ? `${0.0675 * clientHeight}px`
          : { base: "auto", md: "4.39vw" }
      }
      justify={isLandscape ? "center" : "left"}
      align="center"
      p={isLandscape ? `${0.0117 * clientHeight}px 2.34vw` : "0"}
    >
      <Text
        color="#979797"
        fontSize={{ base: "12px", md: fontSize }}
        ref={ref}
        w="100%"
        h="100%"
        textAlign={isLandscape ? "center" : "left"}
        overflow="hidden"
      >
        {t('footer_disclaimer')} Version {appConfig?.version}
      </Text>
      <Text> 
        <button onClick={() => i18next.changeLanguage('en')}>EN</button>
        <button onClick={() => i18next.changeLanguage('fr')}>FR</button>
      </Text>
    </Flex>
  );
};

export default FooterInstruction;
