import { IVitalsState, VitalsStateAction } from "../types/vitalsState";
import theme from "../utils/theme";
import { VitalsState } from "../types/vitalsState";

export const initialVitalsState = {
  state: VitalsState.CONNECTING,
  stateColor: theme.colors.orange.main,
  isCancelled: false,
};

export const vitalsStateReducer = (
  state: IVitalsState = initialVitalsState,
  action: VitalsStateAction
) => {
  // If scan is cancelled, fix the state as is
  if (state.isCancelled) return state;

  switch (action.type) {
    case VitalsState.CONNECTING:
      return {
        state: VitalsState.CONNECTING,
        stateColor: theme.colors.orange.main,
        isCancelled: false,
      };
    case VitalsState.SEARCHING:
      return {
        state: VitalsState.SEARCHING,
        stateColor: theme.colors.orange.main,
        isCancelled: false,
      };
    case VitalsState.SCANNING:
      return {
        state: VitalsState.SCANNING,
        stateColor: theme.colors.yellow.main,
        isCancelled: false,
      };
    case VitalsState.SUCCESS:
      return {
        state: VitalsState.SUCCESS,
        stateColor: theme.colors.green.main,
        isCancelled: false,
      };
    case VitalsState.DISCONNECTED:
      return {
        state: VitalsState.DISCONNECTED,
        stateColor: theme.colors.red.main,
        isCancelled: false,
      };
    case VitalsState.INCOMPLETE:
      return {
        state: VitalsState.INCOMPLETE,
        stateColor: theme.colors.red.main,
        isCancelled: false,
      };
    case VitalsState.STARTING:
      return {
        state: VitalsState.STARTING,
        stateColor: theme.colors.red.main,
        isCancelled: false,
      };
    case VitalsState.WEBRTC_FAILED:
      return {
        state: VitalsState.WEBRTC_FAILED,
        stateColor: theme.colors.red.main,
        isCancelled: false,
      };
    case VitalsState.INVALID:
      return {
        state: VitalsState.INVALID,
        stateColor: theme.colors.red.main,
        isCancelled: false,
      };
    case "CANCEL_SCAN":
      return {
        ...state,
        stateColor: theme.colors.red.main,
        isCancelled: true,
      };
    default:
      return state;
  }
};
