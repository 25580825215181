import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const fallbackLng = ['en']
const availableLanguages = ['en', 'fr']
const detectionOptions = {
    // order and from where user language should be detected
    order: ['cookie', 'localStorage', 'navigator'],
    // cache user language on
    caches: ['localStorage', 'cookie'],
    //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    // only detect languages that are in the whitelist
    checkWhitelist: true,
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        detection: detectionOptions,
        debug:false,
        supportedLngs:availableLanguages,
    })

export default i18n