export enum VitalsState {
  CONNECTING = "CONNECTING",
  STARTING = "STARTING",
  SEARCHING = "SEARCHING",
  SCANNING = "SCANNING",
  INCOMPLETE = "INCOMPLETE",
  SUCCESS = "SUCCESS",
  DISCONNECTED = "DISCONNECTED",
  WEBRTC_FAILED = "WEBRTC_FAILED",
  INVALID = "INVALID",
}

export type IVitalsState = {
  state: typeof VitalsState[keyof typeof VitalsState];
  stateColor: string;
  isCancelled: boolean;
};

export type VitalsStateAction = {
  type: string;
  payload?: any;
};

export type IVitalsStateContext = {
  vitalsState: IVitalsState;
  dispatchVitalsState: React.Dispatch<VitalsStateAction>;
};
