import { ISvg } from "../types/image";

export const HeartRateOutlined: React.FC<ISvg> = ({
  w = "30",
  h = "31",
  stroke = "currentColor",
}) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 30 31"
    fill="none"
    stroke={stroke}
    strokeWidth={0}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9983 0.875C18.7483 0.875 16.6483 1.625 14.9983 3.125C13.3483 1.625 11.2483 0.875 8.99829 0.875C6.44829 0.875 4.04829 2.075 2.39829 4.025C0.298287 6.575 -0.451713 10.325 0.298287 14.675C0.448287 15.275 0.598287 15.875 0.748287 16.475C0.748287 16.475 0.748287 16.475 0.748287 16.625C3.44829 24.425 13.6483 30.575 14.2483 30.725C14.6983 31.025 15.2983 31.025 15.7483 30.725C16.1983 30.425 26.3983 24.275 29.2483 16.625C29.2483 16.625 29.2483 16.625 29.2483 16.475C29.3983 15.875 29.5483 15.275 29.6983 14.675C30.4483 10.325 29.6983 6.575 27.5983 4.025C25.9483 2.075 23.5483 0.875 20.9983 0.875ZM14.9983 27.575C12.8983 26.225 7.04829 22.175 4.49829 17.375H10.4983C10.9483 17.375 11.3983 17.075 11.6983 16.775L13.0483 14.825L15.1483 20.975C15.4483 21.725 16.1983 22.175 17.0983 21.875C17.3983 21.725 17.6983 21.575 17.8483 21.275L20.3983 17.375H25.4983C22.9483 22.175 17.0983 26.225 14.9983 27.575ZM26.6983 14.075C26.6983 14.225 26.6983 14.225 26.6983 14.375H19.4983C19.0483 14.375 18.5983 14.675 18.2983 14.975L16.9483 16.925L14.8483 10.925C14.6983 10.175 13.7983 9.725 13.0483 10.025C12.7483 10.025 12.4483 10.325 12.2983 10.475L9.74829 14.375H3.29829C3.29829 14.225 3.29829 14.225 3.29829 14.075C2.69829 10.625 3.14829 7.775 4.79829 5.825C5.69829 4.625 7.34829 3.875 8.99829 3.875C10.7983 3.725 12.5983 4.625 13.6483 6.275C13.9483 6.725 14.3983 7.025 14.9983 7.025C15.5983 7.025 16.0483 6.725 16.3483 6.275C17.3983 4.625 19.0483 3.725 20.9983 3.875C22.6483 3.875 24.2983 4.625 25.3483 5.975C26.8483 7.775 27.2983 10.775 26.6983 14.075Z"
      fill={stroke}
    />
  </svg>
);
