import { Box, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { VitalsStateContext } from "../../App";
import { INSTRUCTIONS } from "../../consts/instructionTexts";
import { useTranslation } from "react-i18next";

const Instruction = () => {
  const {
    vitalsState: { state, isCancelled },
  } = useContext(VitalsStateContext)!;

  const {t} = useTranslation();
  return (
    <Box>
      <Text
        fontSize={{ base: "26px", lg: "48px" }}
        textAlign="center"
        fontWeight="550"
        lineHeight="1.25"
      >
        {!isCancelled
          ? t(INSTRUCTIONS[state])
          : t('scanning_stopped')}
      </Text>
    </Box>
  );
};

export default Instruction;
