import { Stack, Image, AspectRatio, Box, Text } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { VitalsState } from "../../types/vitalsState";
import SuccessCheck from "../../images/success.png";
import ErrorImage from "../../images/error.png";
import Result from "./Result";
import { RTCContext, VitalsStateContext, MediaContext } from "../../App";
import theme from "../../utils/theme";
import { isMobile } from "react-device-detect";
import { useURLQuery } from "../../hooks/useURLQuery";
import FlipCameraButton from "./FlipCameraButton";
import VitalsItems from "./VitalsItems";
import { useTranslation } from "react-i18next";

const DEFAULT_ICE_SERVERS = [{ urls: "stun:stun.l.google.com:19302" }];
let incomingVitals: any = null;
let vitalsCheckInterval: any = null;
let localConnection: RTCPeerConnection | null = null;
let facingMode: any;

export const Video = () => {
  const webSocket = useRef<WebSocket>();
  const videoRef: any = useRef();
  const [socketMessages, setSocketMessages] = useState<any[]>([]);
  const [rtcConnection, setRtcConnection] = useState<RTCPeerConnection>();
  const [stream, setStream] = useState<any>(null);
  const [vitals, setVitals] = useState<any>({});
  const { isLandscape, clientHeight, clientHeightStr, setVideoInputDevices } =
    useContext(MediaContext)!;
  const { setDataChannel } = useContext(RTCContext)!;
  const {
    vitalsState: { state, stateColor, isCancelled },
    dispatchVitalsState,
  } = useContext(VitalsStateContext)!;
  const { cameraQuery, token } = useURLQuery();
  const {t} = useTranslation();

  /*
   * 'Thread' to handle websocket communication with the signalign server
   */
  useEffect(() => {
    webSocket.current = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_URL ||
        "wss://demo.vitalintelligence.dev/ws"
    );
    webSocket.current.onopen = () => {
      console.log("Websocket connected");
      dispatchVitalsState({ type: VitalsState.STARTING });
      /*
        * Only validate the token if it is available.
        * Otherwise, continue directly (at least, for now)
        */
      if ((token !== null) && (token.length > 0)) {
        console.log("Validating token:",token);
        validateToken(token);
      }
      else {
        connectWebRTC();
      }
    };
    webSocket.current.onmessage = (message: any) => {
      const data = JSON.parse(message.data);
      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = (e: any) => {
      console.log("Closing websocket:", e);
      webSocket?.current?.close();
    };
    return () => webSocket?.current?.close();
  }, []);

  /*
   * Thread to handle websocket messages
   */
  useEffect(() => {
    /*
     * when vitals engine answers to our offer
     */
    const onAnswer = (answer: any) => {
      if (rtcConnection) {
        console.log("Processing answer");
        rtcConnection.setRemoteDescription(new RTCSessionDescription(answer));
      }
    };

    /*
     * when we got ice candidate from another vitals engine
     */
    const onCandidate = (candidate: any) => {
      if (rtcConnection) {
        console.log("Processing candidate");
        rtcConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    };
    /*
     * When we got a response from token validation
     */
    const onValidate = (response: any) => {
      if (response.success) {
        console.log("Token validated. Starting WebRTC communication");
        connectWebRTC();
      }
      else {
        console.log("Token invalid. Reason:", response.message);
        dispatchVitalsState({ type: VitalsState.INVALID });
        stopCommunication(null);
      }
    }

    let data = socketMessages.pop();
    if (data) {
      switch (data.type) {
        case "answer":
          onAnswer(data.message);
          break;
        case "candidate":
          onCandidate(data.message);
          break;
        case "validate":
          onValidate(data.message);
          break;
        default:
          break;
      }
    }
  }, [socketMessages, rtcConnection]);

  const handleConnection = async (rtcConnection: RTCPeerConnection) => {
    /*
     * Setup datachannel
     */
    const channel = rtcConnection.createDataChannel("vitals");
    setDataChannel(channel);
    channel.onerror = (error) => {
      console.log("Datachannel error:", error);
    };
    channel.onopen = () => {
      console.log("WebRTC datachannel connected");
      dispatchVitalsState({ type: VitalsState.SEARCHING });
      /*
       * Sending command to start vitals
       */
      channel.send(
        JSON.stringify({
          type: "command",
          message: "start",
        })
      );
    };
    channel.onmessage = (data: any) => {
      console.log("message received: ", data.data);
      const message = JSON.parse(data.data);
      console.log(message);
      if (message?.type === "response") {
        if (!message?.success) {
          console.log("Problem executing command: ", message?.message);
          return;
        }
        switch (message?.command) {
          case "start":
            console.log("Handling 'start' response");
            startPeriodicCheck(channel);
            break;
          case "stop":
            stopCommunication(channel);
            break;
          case "state":
            incomingVitals = message?.message;
            processVitals(channel);
            break;
        }
      }
    };

    /*
     * Video setup
     */
    facingMode =
      isMobile && cameraQuery === "true" ? { exact: "environment" } : "user";
    var constraints = {
      video: {
        width: 640,
        height: 480,
        frameRate: { ideal: 30, max: 30 },
        facingMode,
      },
    };

    const video: any = videoRef.current;
    navigator.mediaDevices.getUserMedia(constraints).then(
      async function (stream) {
        video.srcObject = stream;
        setStream(stream);
        stream.getTracks().forEach(function (track) {
          console.log("Adding video track");
          rtcConnection.addTrack(track, stream);
        });

        // Count how many devices can input video
        // This must be processed after executing getUserMedia because before getUserMedia, some mobile devices or browsers do not detect all devices that input video
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoInputs = mediaDevices.filter(
          ({ kind }) => kind === "videoinput"
        );
        setVideoInputDevices(videoInputs);

        /*
         * Sending offer
         */
        console.log("Sending offer");
        rtcConnection
          .createOffer()
          .then((offer) => rtcConnection.setLocalDescription(offer))
          .then(() =>
            webSocket?.current?.send(
              JSON.stringify({
                type: "offer",
                message: rtcConnection.localDescription,
              })
            )
          )
          .catch((e) => console.log("WebRTC offer error:", e));
      },
      function (err) {
        console.log("Could not acquire media: " + err);
      }
    );
  };

  const onConnectionStateChange = () => {
    console.log(
      "connection.connectionState: ",
      localConnection?.connectionState
    );

    // Show the error when the scan has not passed and connection state is failed
    if (!incomingVitals?.valid && localConnection?.connectionState === "failed")
      dispatchVitalsState({ type: VitalsState.WEBRTC_FAILED });

    // Show the error when the scan has not passed and connection state is disconnected
    if (
      incomingVitals?.finished === true &&
      !incomingVitals?.valid &&
      localConnection?.connectionState === "disconnected"
    )
      dispatchVitalsState({ type: VitalsState.DISCONNECTED });
  };

  const validateToken = (token: string) => {
    webSocket?.current?.send(
      JSON.stringify({
        type: "validate",
        message: token,
      })
    );
  }

  const connectWebRTC = () => {
    localConnection = new RTCPeerConnection({
      iceCandidatePoolSize: 1,
      iceServers: DEFAULT_ICE_SERVERS,
    });

    //when the browser finds an ice candidate we send it to another peer
    localConnection.onicecandidate = ({ candidate }) => {
      console.log("Sending candidate:");
      if (candidate) {
        webSocket?.current?.send(
          JSON.stringify({
            type: "candidate",
            message: candidate,
          })
        );
      }
    };

    localConnection.onconnectionstatechange = onConnectionStateChange;
    localConnection.onicegatheringstatechange = () => {
      console.log(
        "connection.iceGatheringState: ",
        localConnection?.iceGatheringState
      );
      !localConnection?.iceGatheringState &&
        dispatchVitalsState({ type: VitalsState.WEBRTC_FAILED });
    };
    setRtcConnection(localConnection);
    /*
     * Handle the connection
     */
    handleConnection(localConnection);
  };

  // Reassign call back function when scan finished or incoming vitals info changed
  useEffect(() => {
    if (localConnection)
      localConnection.onconnectionstatechange = onConnectionStateChange;
  }, [
    incomingVitals?.finished,
    incomingVitals?.valid,
    onConnectionStateChange,
  ]);

  const startPeriodicCheck = (dataChannel: RTCDataChannel) => {
    vitalsCheckInterval = setInterval(() => {
      dataChannel.send(
        JSON.stringify({
          type: "command",
          message: "state",
        })
      );
    }, 1000);
  };

  const stopCommunication = (dataChannel: any) => {
    /*
     * Stop the periodic request for vitals
     */
    if (vitalsCheckInterval) {
      clearInterval(vitalsCheckInterval);
    }
    /*
     * Close datachannel
     */
    if (dataChannel) {
      dataChannel.close();
    }
    /*
     * Send websocket message to close connection
     */
    webSocket?.current?.send(
      JSON.stringify({
        type: "finish",
        message: "",
      })
    );
    /*
     * Stop websockets
     */
    console.log("websockets=",webSocket);
    webSocket?.current?.close();
  };

  const processVitals = (dataChannel: RTCDataChannel) => {
    console.log("incomingVitals:", incomingVitals);
    setVitals(incomingVitals);

    if (incomingVitals?.finished) {
      incomingVitals?.valid
        ? dispatchVitalsState({ type: VitalsState.SUCCESS })
        : dispatchVitalsState({ type: VitalsState.INCOMPLETE });

      /*
       * In case where the calculation end is detected,
       * send the command to stop the calculation
       */
      dataChannel.send(
        JSON.stringify({
          type: "command",
          message: "stop",
        })
      );
    } else {
      incomingVitals?.faceDetected
        ? dispatchVitalsState({ type: VitalsState.SCANNING })
        : dispatchVitalsState({ type: VitalsState.SEARCHING });
    }
  };

  const rescan = () => {
    window.location.reload();
  };

  return (
    <Stack
      w={isLandscape ? "66vw" : "100vw"}
      h={isLandscape ? clientHeightStr : "75vw"}
      spacing={0}
      position="relative"
      align="center"
      justify={isLandscape ? "center" : "space-between"}
      zIndex="1"
      bgColor="#000"
    >
      {stream && (
        <>
          <Stack
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height={isLandscape ? clientHeightStr : "75vw"}
            justify={
              incomingVitals?.finished ||
              isCancelled ||
              state === VitalsState.STARTING ||
              state === VitalsState.WEBRTC_FAILED ||
              state === VitalsState.DISCONNECTED
                ? "center"
                : "space-between"
            }
            align="center"
            pt={
              !isLandscape ||
              incomingVitals?.finished ||
              isCancelled ||
              state === VitalsState.STARTING ||
              state === VitalsState.WEBRTC_FAILED ||
              state === VitalsState.DISCONNECTED
                ? "0"
                : { base: 0, lg: `calc((${clientHeightStr} - 49.5vw) / 2)` }
            }
            px="0"
            background={
              incomingVitals?.finished ||
              isCancelled ||
              state === VitalsState.STARTING ||
              state === VitalsState.WEBRTC_FAILED ||
              state === VitalsState.DISCONNECTED
                ? "rgba(0,0,0,0.8)"
                : "none"
            }
            zIndex="10"
          >
            {isCancelled ? (
              <Box>
                <Result
                  rescan={rescan}
                  colour={theme.colors.red.main}
                  Icon={
                    <Image
                      src={ErrorImage}
                      w={isLandscape ? "15.02vw" : "22.75vw"}
                      h={isLandscape ? "15.08vw" : "22.85vw"}
                    />
                  }
                  text={t('video_scan_cancelled')}
                />
              </Box>
            ) : !incomingVitals?.finished &&
              state !== VitalsState.DISCONNECTED &&
              state !== VitalsState.INCOMPLETE &&
              state !== VitalsState.WEBRTC_FAILED &&
              state !== VitalsState.SUCCESS ? (
              <>
                {state === VitalsState.STARTING ? (
                  <Box>
                    <Text
                      color="#fff"
                      fontSize={
                        isLandscape
                          ? {
                              base: "28px",
                              md: "32px",
                              lg: "48px",
                            }
                          : {
                              base: "26px",
                              md: "48px",
                              lg: "64px",
                            }
                      }
                      fontWeight="550"
                      lineHeight="1.4"
                      textAlign="center"
                    >
                      {t('video_please_wait')}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    width={isLandscape ? "17.358vw" : "26.3vw"}
                    height={isLandscape ? "27.06vw" : "41vw"}
                    border={`solid ${stateColor}`}
                    borderWidth={
                      isLandscape
                        ? { base: "6px", lg: "10px" }
                        : { base: "4px", md: "10px" }
                    }
                    borderRadius="100vw"
                    mt={isLandscape ? `${clientHeight * 0.128}px` : "14.55vw"}
                  />
                )}
                <Box
                  mb={
                    isLandscape
                      ? {
                          base: `${clientHeight * 0.0425}px !important`,
                          lg: `calc((${clientHeightStr} - 49.5vw) / 4) !important`,
                          xxl: "1.5vw !important",
                        }
                      : { base: "2vw !important", xxl: "1.5vw !important" }
                  }
                  transform={
                    isLandscape
                      ? {
                          base: "none",
                          lg: "translateY(50%)",
                          xxl: "none",
                        }
                      : "none"
                  }
                >
                  <VitalsItems vitals={vitals} />
                </Box>
              </>
            ) : (
              <Box>
                {incomingVitals?.valid ? (
                  <>
                    <Result
                      rescan={rescan}
                      colour={theme.colors.green.main}
                      Icon={
                        <Image
                          src={SuccessCheck}
                          w={isLandscape ? "14.96vw" : "22.66vw"}
                          h={isLandscape ? "14.96vw" : "22.66vw"}
                        />
                      }
                      text={t('video_scan_successfull')}
                    />
                    <Box
                      position="absolute"
                      bottom={
                        isLandscape
                          ? {
                              base: `${clientHeight * 0.0425}px !important`,
                              lg: `calc((${clientHeightStr} - 49.5vw) / 4) !important`,
                              xxl: "1.5vw !important",
                            }
                          : { base: "2vw !important", xxl: "1.5vw !important" }
                      }
                      left="50%"
                      transform={
                        isLandscape
                          ? {
                              base: "translate(-50%, 0)",
                              lg: "translate(-50%, 50%)",
                              xxl: "translate(-50%, 0)",
                            }
                          : "translate(-50%, 0)"
                      }
                    >
                      <VitalsItems vitals={vitals} />
                    </Box>
                  </>
                ) : (
                  <Result
                    rescan={rescan}
                    colour={theme.colors.red.main}
                    Icon={
                      <Image
                        src={ErrorImage}
                        w={isLandscape ? "15.02vw" : "22.75vw"}
                        h={isLandscape ? "15.08vw" : "22.85vw"}
                      />
                    }
                    text={t('video_unable')}
                  />
                )}
              </Box>
            )}
          </Stack>
          <FlipCameraButton
            top={isLandscape ? `${clientHeight * 0.044}px` : "1.5vw"}
            left={isLandscape ? `${clientHeight * 0.044}px` : "1.5vw"}
          />
        </>
      )}
      <Stack
        w="100%"
        transform={facingMode === "user" ? "scaleX(-1)" : "initial"}
        zIndex="5"
        bgColor="#000"
      >
        <AspectRatio ratio={4 / 3} maxH={clientHeightStr} w="100%">
          <video autoPlay playsInline muted ref={videoRef} />
        </AspectRatio>
      </Stack>
    </Stack>
  );
};
