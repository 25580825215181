import { Box, HStack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { MediaContext } from "../../App";

const VitalsItem = ({ Icon, name, value, colour }: any) => {
  const { isLandscape, clientHeightStr } = useContext(MediaContext)!;

  return (
    <>
      {value && (
        <HStack
          justify="space-between"
          align="center"
          bg={colour}
          p={
            isLandscape
              ? { base: "0 1.25vw", lg: "1.29vw" }
              : { base: "2vw", md: "1.95vw" }
          }
          h={
            isLandscape
              ? { base: `calc(${clientHeightStr} * 0.115)`, lg: "6.34vw" }
              : { base: "10vw", md: "9.6vw" }
          }
          spacing={isLandscape ? "1.287vw" : "1.95vw"}
          color="#000"
          borderRadius={{ base: "2px", md: "3px" }}
        >
          <Box>{Icon}</Box>
          <Text
            fontSize={
              isLandscape
                ? {
                    base: "12px",
                    lg: "16px",
                  }
                : {
                    base: "11px",
                    md: "16px",
                    lg: "20px",
                  }
            }
            lineHeight="1"
            whiteSpace="pre-wrap"
          >
            {name}
          </Text>
          <Text
            fontSize={
              isLandscape
                ? {
                    base: "20px",
                    lg: "32px",
                  }
                : {
                    base: "20px",
                    md: "32px",
                    lg: "40px",
                  }
            }
          >
            {value}
          </Text>
        </HStack>
      )}
    </>
  );
};

export default VitalsItem;
